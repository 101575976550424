.lds-ring {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 8px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.18ab39c9.css.map */
